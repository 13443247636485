import React, { useEffect, useState } from "react";
import "./index.scss";

const AccessDenied: React.FC = () => {
  const [text, setText] = useState<string>("");
  const codeText = "if (user.isInvited) { access.grant(); }";

  useEffect(() => {
    let index = -1;
    let typingFinished = false;
    let typeInterval: NodeJS.Timeout;

    const animateCode = () => {
      setText("");
      index = -1;

      typeInterval = setInterval(() => {
        if (index < codeText.length - 1) {
          index++;
          setText((prev) => prev + codeText[index]);
        } else {
          clearInterval(typeInterval);
          if (!typingFinished) {
            typingFinished = true;
            setTimeout(() => {
              typingFinished = false;
              animateCode();
            }, 800);
          }
        }
      }, 100);
    };

    animateCode();

    // 清理定时器
    return () => clearInterval(typeInterval);
  }, []);

  return (
    <div className="access-denied">
      <div className="access-denied-code">
        <div className="circle-box">
          <div className="circle circle-1"></div>
          <div className="circle circle-2"></div>
          <div className="circle circle-3"></div>
        </div>
        <div className="font-mono">
          <span>{">"}</span> {text}
          <span className="animate-pulse">|</span>
        </div>
      </div>
      <div className="access-denied-main">
        <div className="animate-bounce">🚫</div>
        <h1 className="access-denied-main-title">Access Denied!</h1>
        <p className="access-denied-main-text">
          You're not on the invite list yet
        </p>
        <div className="access-denied-main-tip">
          <p>Don't worry, it's not you</p>
          <p>You might be too awesome for us right now!</p>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
