/*
 *接口地址
 */
const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || "development";

// 接口域名
const getPrefix = (env: string) => {
  const innerPrefix = window.location.origin;
  let prefix = "";

  switch (env) {
    case "development":
      prefix = `${innerPrefix}`;
      prefix = "https://prismer.app/api";
      // prefix = "http://192.168.31.41:8080";
      break;
    case "stg":
      prefix = `${innerPrefix}/api`;
      break;
    case "production":
      prefix = `${innerPrefix}/api`;
      break;
  }

  return prefix;
};

// 接口路径
const Apis = {
  chat: "/code",
  fixCode: "/code/fix",
  test: "/code/test",
  download: "/code/download/",
  models: "/models"
};

const Api: any = Apis;
for (const item in Api) {
  Api[item] = getPrefix(BUILD_ENV) + Api[item];
}

export const Prefix = getPrefix(BUILD_ENV);
export default Api;
