interface responseType {
  project: string;
  progress: string;
  markdown: string;
  error: string;
  status: string;
  test: string;
}

const AiResponseType: responseType = {
  project: "project_info",
  progress: "progress",
  markdown: "markdown",
  error: "error",
  status: "status",
  test: "test"
};

interface type {
  loading: string;
  answerLoading: string;
  answerDone: string;
  question: string;
  test: string;
}

const ContentType: type = {
  loading: "loading",
  answerLoading: "answerLoading",
  answerDone: "answerDone",
  question: "question",
  test: "test"
};

const defaultProgressData = [
  {
    type: "Module",
    progress: 0,
    currentContent: ""
  },
  {
    type: "File",
    progress: 0,
    currentContent: ""
  },
  {
    type: "Class",
    progress: 0,
    currentContent: ""
  },
  {
    type: "Method",
    progress: 0,
    currentContent: ""
  }
];

const sucProgressData = [
  {
    type: "Module",
    progress: 100,
    currentContent: ""
  },
  {
    type: "File",
    progress: 100,
    currentContent: ""
  },
  {
    type: "Class",
    progress: 100,
    currentContent: ""
  },
  {
    type: "Method",
    progress: 100,
    currentContent: ""
  }
];

export { AiResponseType, defaultProgressData, sucProgressData, ContentType };
