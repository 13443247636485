import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Input, Drawer, Button, message, Collapse } from "antd";
import {
  HistoryOutlined,
  ProfileOutlined,
  ReloadOutlined,
  CopyOutlined,
  CheckOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import { Api, SendGetRequest, Constant, Utils } from "@/common";
import { setAiData } from "@/common/store/slices/aiDataSlice";
import CommonFun from "../common";
import { AiResponseType, sucProgressData } from "../config";
import {
  FileTree,
  TestResult,
  OperationLog,
  Markdown,
  CodeEditor
} from "../../component";
import classnames from "classnames";
import "./index.scss";
import fixLoadingIcon from "@/assets/icons/fix_loading.gif";

const { Panel } = Collapse;

const { TextArea } = Input;

const ContainerCode = (props: any) => {
  const { data, changeDataCallBack, className, testData, fixCallBack } = props;
  const { id, fileNode } = data;
  const [desValue, setDesValue] = useState<any>({});
  const [changeDesValue, setChangeDesValue] = useState("");
  const [methodName, setMethodName] = useState("");
  const [code, setCode] = useState("");
  const [openOperationLog, setOpenOperationLog] = useState(false);
  const [openTestResult, setOpenTestResult] = useState(false);
  const [hasMethodDetail, setHasMethodDetail] = useState(false);
  const [hasCode, setHasCode] = useState(false);
  const [fileTreeData, setFileTreeData] = useState<any>([]);
  const [isCopy, setIsCopy] = useState(false);
  const [isShowManageDes, setIsShowManageDes] = useState(false);
  const [testResultData, setTestResultData] = useState<any>([]);
  const [curNodeData, setCurNodeData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [curCodeData, setCurCodeData] = useState<any>(null);
  const [isFixing, setIsFixing] = useState(false);
  const regenerateData = useRef<any>([]);
  const dispatch = useDispatch();
  const ClassType = "classes";
  const currentController = useRef<AbortController | null>(null);
  const [manageDesIsCode, setManageDesIsCode] = useState(false);
  const [isError, setIsError] = useState(false);

  // 判断是否是需要过滤的文件类型
  const isFilteredFile = (title: string) =>
    /\.(png|jpg|pdf|txt|zip)$/i.test(title) || title === "";

  const processData = (data: any) => {
    let uniqueKeyCounter = 0;

    // 生成唯一key
    const generateUniqueKey = () => `key-${uniqueKeyCounter++}`;

    // 处理函数: 对 AST 类型进行处理
    const processASTNode = (
      node: any,
      newNode: any,
      parentPath: string,
      level: number = 0
    ) => {
      if (node.ast) {
        newNode.isLeaf = false;
        newNode.isManage = false;
        newNode.isFile = true;
        newNode.children = [];

        // 处理函数和类
        ["functions", ClassType].forEach((type) => {
          const t = type === ClassType ? "class" : "function";

          if (node.ast[type]) {
            newNode.children.push({
              title: type,
              key: generateUniqueKey(),
              isService: true,
              isLeaf: false,
              code: node.code,
              content: node.content,
              type: t,
              path: `${parentPath}/${type}`,
              level: level + 1,
              children: node.ast[type].map((item: any) => ({
                title: item.name,
                key: generateUniqueKey(),
                isMethod: type !== ClassType,
                content: item.content,
                isLeaf: type !== ClassType,
                isClass: type === ClassType,
                description: item.description,
                code: item.code,
                type: t,
                path: `${parentPath}`,
                level: level + 2,
                children:
                  type === ClassType && item.methods
                    ? item.methods.map((i: any) => ({
                        title: i.name,
                        pName: item.name,
                        key: generateUniqueKey(),
                        isMethod: true,
                        content: i.content,
                        isLeaf: true,
                        description: i.description,
                        code: i.code,
                        type: "method",
                        path: `${parentPath}`,
                        level: level + 3
                      }))
                    : []
              }))
            });
          }
        });
      }
    };

    // 处理每个节点
    const processNode = (
      node: any,
      parentPath: string = "",
      level: number = 0
    ) => {
      // 过滤掉不需要的节点
      if (isFilteredFile(node.title)) return null;

      const newNode: any = {
        title: node.title,
        isLeaf: !node.children,
        status: node.status,
        key: generateUniqueKey(),
        isManage: node.children,
        isFile: !node.children,
        content: node.content,
        code: node.code,
        level: level,
        path:
          parentPath === ""
            ? node.children
              ? `${node.title}/`
              : `${node.title}`
            : `${parentPath}${node.title}`
      };

      // 处理 AST 类型的节点
      processASTNode(node, newNode, newNode.path, level);

      // 如果没有 AST 但有 children，递归处理
      if (!node.ast && node.children) {
        newNode.children = node.children
          .map((child: any) => processNode(child, newNode.path, level + 1)) // 传递父节点的路径
          .filter((child: any) => child !== null);
      }

      return newNode;
    };

    // 处理并返回过滤后的树形数据
    return data
      .map((node: any) => processNode(node, ""))
      .filter((node: any) => node !== null);
  };

  // 处理单测数据
  const extractTestResults = (data: any) => {
    // 判断 title 是否包含扩展名，用于区分文件夹和文件
    const isFile = (title: string) => /\.[a-zA-Z0-9]+$/.test(title); // 判断是否包含扩展名

    // 递归函数，用于处理数据
    const processNode = (
      node: any,
      parentTitle: string = "",
      level: number = 0
    ) => {
      // 拼接当前节点的 title（根据层级判断是否拼接父级title）
      const currentTitle =
        level > 1 ? `${parentTitle} / ${node.title}` : node.title;

      // 判断当前节点是否是文件
      if (isFile(node.title)) {
        // 如果是文件且有 test_result，返回文件信息
        if (node.hasOwnProperty("test_result")) {
          return [
            {
              title: currentTitle, // 如果是二级及更深层级，拼接父级 title
              test_result: node.test_result,
              test_info:
                node.test_info.length > 0 ? node.test_info.split("\n") : []
            }
          ];
        }
        return []; // 如果是文件但没有 test_result，则不返回
      }

      // 如果是文件夹，递归处理子节点
      if (node.children && node.children.length > 0) {
        const childrenResults = node.children
          .map((child: any) => processNode(child, currentTitle, level + 1)) // 递归处理子节点
          .flat(); // 展平数组

        return childrenResults; // 返回处理后的所有子节点
      }

      return []; // 如果没有子节点且不是文件，则返回空数组
    };

    // 最终的结果数组
    const result: any = [];

    // 遍历输入数据
    data.forEach((item: any) => {
      const results = processNode(item); // 处理每一项的 children
      if (isFilteredFile(item.title)) return null;

      result.push({
        ...item, // 保留最外层文件夹的 title
        test_info: item.test_info.length > 0 ? item.test_info.split("\n") : [],
        results: item.children && item.children.length > 0 ? results : [] // 将所有子集的结果放到 results 中
      });
    });

    return result;
  };

  // 选择文件的回调
  const seleteTreeNodeCallBack = (nodeData: any) => {
    regenerateData.current = [];
    setChangeDesValue("");
    setCurNodeData(nodeData);
  };

  // 复制代码
  const copyCode = () => {
    navigator.clipboard.writeText(code);
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  const onApplySuggest = useCallback(() => {
    if (isFixing) return;
    const { type, title, path, pName } = curNodeData;
    const t = pName ? title : `${pName}.${title}`;
    const existingItemIndex = regenerateData.current.findIndex(
      (f: any) => f.name === t && f.path === path
    );

    if (existingItemIndex === -1) {
      regenerateData.current.push({
        type,
        name: type === "method" ? `${pName}.${title}` : title,
        path,
        fix_description: desValue
      });
    } else {
      regenerateData.current[existingItemIndex] = {
        ...regenerateData.current[existingItemIndex],
        fix_description: desValue
      };
    }
    regenerate();
  }, [desValue, curNodeData]);

  // 修改description向大模型发送请求
  const regenerate = () => {
    const reqData = {
      generate_trace_id: id,
      fixes: regenerateData.current,
      model: Utils.getLocalStorage("model")
    };

    // 如果存在正在进行的请求，则取消它
    if (currentController.current) {
      currentController.current.abort();
    }

    // 创建一个新的 AbortController 来控制当前请求
    const controller = new AbortController();
    currentController.current = controller;

    setIsFixing(true);
    setLoading(true);

    CommonFun.fetchFun(
      Api.fixCode,
      reqData,
      (res: any) => {
        handleAiResponse(res);
      },
      () => {
        setLoading(false);
        setIsFixing(false);
      },
      () => {
        setLoading(false);
        setIsFixing(false);
        regenerateData.current = [];
      },
      controller.signal
    );
  };

  // 处理大模型返回的数据
  const handleAiResponse = (data: any) => {
    const { type, answer } = data;

    if (type === AiResponseType.project) {
      setCurCodeData(answer);
    } else if (type === AiResponseType.error) {
      message.error("Something went wrong, please try again");
      setIsError(true);
    }
  };

  const resetData = () => {
    setHasCode(false);
    setHasMethodDetail(false);
    setCode("");
    setDesValue("");
    setMethodName("");
  };

  const findNodeByKey = (treeData: any, key: any) => {
    for (let i = 0; i < treeData.length; i++) {
      const node = treeData[i];
      if (node.key === key) {
        return node; // 找到节点返回
      }
      if (node.children) {
        const found: any = findNodeByKey(node.children, key); // 递归查找子节点
        if (found) {
          return found;
        }
      }
    }
    return null; // 没有找到
  };

  const downLoad = () => {
    SendGetRequest(Api.download + id, {}, { responseType: "blob" }).then(
      (res: any) => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = url;
        a.download = "code";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    );
  };

  const getDesData = (text: string) => {
    // 通用的块解析函数
    const parseBlock = (text: string, blockNames: string[]) => {
      for (const blockName of blockNames) {
        const pattern = new RegExp(`${blockName}:\\s*((?:.*\\S.*\\n?)+)`);
        const match = text.match(pattern);
        if (match) {
          // 分行解析每个条目
          const lines = match[1]
            .split("\n")
            .filter((line) => line.trim() !== "");
          const blockData = lines.map((line) => {
            const [key, ...valueParts] = line
              .split(":")
              .map((part) => part.trim());
            return { name: key, value: valueParts.join(":") };
          });

          // 去掉匹配部分的文本
          const remainingText = text.replace(match[0], "").trim();

          return { data: blockData, remainingText };
        }
      }
      return { data: null, remainingText: text }; // 如果都没匹配到
    };

    // 定义多种可能的块名
    const argsBlockNames = ["Args", "Parameters", "参数"];
    const returnsBlockNames = ["Returns", "返回"];

    // 解析各个块并得到剩余文本
    const { data: argsInfo, remainingText: remainingAfterArgs } = parseBlock(
      text,
      argsBlockNames
    );
    const { data: returnsInfo, remainingText: remainingAfterReturns } =
      parseBlock(remainingAfterArgs, returnsBlockNames);

    // 动态构建 JSON 结构
    const parameters = [];
    if (argsInfo && argsInfo.length > 0) {
      parameters.push({
        type: "Args",
        details: argsInfo
      });
    }

    // 如果 parameters 为空且没有 returns，返回空对象
    const result: any = {};
    if (parameters.length > 0) {
      result.parameters = parameters;
    }
    if (returnsInfo && returnsInfo.length > 0) {
      result.returns = returnsInfo;
    }

    // 剩余的文本作为 test 字段
    result.test = remainingAfterReturns;

    return result;
  };

  useEffect(() => {
    if ((!curCodeData && !loading) || isError) return;
    const curAllData = {
      id,
      progressData: sucProgressData,
      fileNode: curCodeData
    };

    // dispatch(setAiData(curAllData));
    changeDataCallBack(curAllData, loading, false);
  }, [curCodeData, loading, isError]);

  useEffect(() => {
    if (!fileNode) return;
    // resetData();
    setFileTreeData(processData(CommonFun.sortData(fileNode)));
  }, [fileNode]);

  useEffect(() => {
    const { key } = curNodeData;

    const a = findNodeByKey(fileTreeData, key);
    if (!a) return;
    const {
      title,
      content,
      isMethod,
      description,
      isFile,
      code,
      isManage,
      isService,
      isClass
    } = a;
    setHasMethodDetail(isMethod);
    setIsShowManageDes(false);

    if (isMethod || isClass) {
      setMethodName(title);
      setDesValue(description ? getDesData(description) : {});
      setCode(content);
      setHasCode(true);
    } else if (isFile || isService) {
      setCode(code);
      setHasCode(true);
    } else if (isManage) {
      if (code === "") {
        setHasCode(false);
        return;
      }
      setCode(code);
      setHasCode(true);
      setIsShowManageDes(true);
      setManageDesIsCode(/^[\w-]+\.\w+$/.test(title));
    } else {
      setHasCode(false);
    }
  }, [fileTreeData, curNodeData]);

  useEffect(() => {
    if (!testData) return;
    setTestResultData(extractTestResults(CommonFun.sortData(testData)));
  }, [testData]);

  useEffect(() => {
    fixCallBack(isFixing);
  }, [isFixing]);

  return (
    <div className={`container-code ${className}`} id="parent-drawer">
      <div className="container-code-title">
        <p>
          Code Generator
          <span>
            (You can add modification suggestions for each method and use the
            Apply button to generate the code you want.)
          </span>
        </p>
        <div className="container-code-title-icons">
          {/* <HistoryOutlined
            onClick={() => {
              setOpenOperationLog(!openOperationLog);
              setOpenTestResult(false);
            }}
          /> */}
          <DownloadOutlined onClick={downLoad} />
          <span
            onClick={() => {
              setOpenTestResult(!openTestResult);
              setOpenOperationLog(false);
            }}
          >
            <ProfileOutlined />
          </span>
        </div>
      </div>
      <div className="container-code-main">
        <div
          className={classnames("container-code-item container-code-l", {
            "container-code-l-full": !hasCode && !hasMethodDetail
          })}
        >
          <h4>Project Structure {isFixing && <img src={fixLoadingIcon} />}</h4>
          <div className="container-code-l-main">
            <FileTree
              showStatusAndBtn={false}
              data={fileTreeData}
              seleteTreeNodeCallBack={seleteTreeNodeCallBack}
              isShowLeafIcon={false}
              className="code-file-tree"
            />
          </div>
        </div>
        {hasMethodDetail && (
          <div className="container-code-item container-code-c">
            <div className="container-code-c-box">
              <h5>Method Details</h5>
              {/* Method Name */}
              <div>
                <h6>Method Name</h6>
                <div className="method-name">{methodName}</div>
              </div>
              {/* Description */}
              {Object.keys(desValue).length > 0 && (
                <div className="des-area-main">
                  <h6>Description</h6>
                  <div className="des-area-box">
                    <pre>{desValue.test}</pre>
                    <Collapse defaultActiveKey={[]}>
                      {desValue.parameters &&
                        desValue.parameters.length > 0 && (
                          <Panel
                            header="Parameters"
                            key="1"
                            className="des-area-p"
                          >
                            {desValue.parameters.map((p: any, idx: number) => (
                              <div key={idx}>
                                <ul>
                                  {p.details.map((d: any) => {
                                    const { name, value } = d;
                                    return (
                                      <li key={name}>
                                        <span className="name">{name}</span>
                                        {value.length > 0 && (
                                          <>
                                            <span> - </span>
                                            <span className="value">
                                              {value}
                                            </span>
                                          </>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            ))}
                          </Panel>
                        )}
                      {desValue.returns && desValue.returns.length > 0 && (
                        <Panel header="Returns" key="2" className="des-area-r">
                          <ul>
                            {desValue.returns.map((r: any) => {
                              const { name, value } = r;
                              return (
                                <li key={name}>
                                  <span className="name">{name}</span>
                                  {value.length > 0 && (
                                    <>
                                      <span> - </span>
                                      <span className="value">{value}</span>
                                    </>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </Panel>
                      )}
                    </Collapse>
                  </div>
                  {/* <Markdown markdownData={desValue} /> */}
                </div>
              )}
              {/* Modification Suggestions */}
              <div className="change-des-area-box">
                <h6>Modification Suggestions</h6>
                <TextArea
                  className="change-des-area"
                  value={changeDesValue}
                  onChange={(e) => setChangeDesValue(e.target.value)}
                />
                <Button
                  type="primary"
                  size="small"
                  onClick={onApplySuggest}
                  className={classnames("apply-suggest", {
                    "apply-suggest-disable": isFixing
                  })}
                >
                  apply
                </Button>
              </div>
            </div>
          </div>
        )}
        {hasCode && (
          <div
            className={classnames("container-code-item container-code-r", {
              "container-code-r-full": !hasMethodDetail
            })}
          >
            {isShowManageDes && !manageDesIsCode && (
              <div className="container-code-r-des">
                <div className="container-code-r-des-main">
                  <h5>Description</h5>
                  <Markdown markdownData={code} />
                </div>
              </div>
            )}
            {(!isShowManageDes || manageDesIsCode) && (
              <>
                <h5>Generated Code</h5>
                <div className="code-main">
                  <div className="code-main-top">
                    <span>Python</span>
                    {!isCopy ? (
                      <CopyOutlined onClick={copyCode} />
                    ) : (
                      <CheckOutlined />
                    )}
                  </div>
                  <div className="code-main-box">
                    <CodeEditor code={code} />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <Drawer
        title="Operation Log"
        placement="bottom"
        closable={false}
        onClose={() => setOpenOperationLog(false)}
        open={openOperationLog}
        height="82%"
        getContainer={() =>
          document.getElementById("parent-drawer") || document.body
        }
      >
        <OperationLog />
      </Drawer>
      <Drawer
        title="Test Results"
        placement="bottom"
        closable={false}
        onClose={() => setOpenTestResult(false)}
        open={openTestResult}
        height="82%"
        getContainer={() =>
          document.getElementById("parent-drawer") || document.body
        }
      >
        <TestResult data={testResultData} />
      </Drawer>
    </div>
  );
};

export default ContainerCode;
