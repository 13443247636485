// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  background: #fff;
  border-radius: 0.0625rem;
  padding: 0.0625rem;
}
.progress > div {
  margin: 0.0125rem 0;
}
.progress-title {
  font-size: 0.0875rem;
  font-weight: bold;
  line-height: 0.125rem;
}
.progress .ant-progress {
  line-height: 0;
}
.progress .ant-progress-bg {
  height: 0.0625rem !important;
}
.progress .ant-progress-text {
  font-size: 0.0875rem;
}
.progress .ant-popover {
  width: auto;
}
.progress .ant-popover-content {
  overflow: auto;
  border-radius: 0.03125rem;
}
.progress .ant-popover-content::-webkit-scrollbar {
  width: 0.025rem;
  height: 0.025rem;
}
.progress .ant-popover-content::-webkit-scrollbar-track {
  background-color: var(--var-prismer-color5);
}
.progress .ant-popover-content::-webkit-scrollbar-thumb {
  border-radius: 0.01875rem;
  background-color: #b2b2b2;
}
.progress .ant-popover-inner {
  font-size: 0.075rem;
  padding: 0.03125rem;
  border-radius: 0.03125rem;
  background-color: #b2b2b2;
}
.progress .ant-popover-arrow::before, .progress .ant-popover-arrow::after {
  background-color: #b2b2b2;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/component/progressBar/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,wBAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;AAAE;EACE,oBAAA;EACA,iBAAA;EACA,qBAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AADI;EACE,4BAAA;AAGN;AADI;EACE,oBAAA;AAGN;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,cAAA;EACA,yBAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;AAGN;AADI;EACE,2CAAA;AAGN;AADI;EACE,yBAAA;EACA,yBAAA;AAGN;AAAE;EACE,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;AAEJ;AACI;EAEE,yBAAA;AAAN","sourcesContent":[".progress {\n  background: #fff;\n  border-radius: 10px;\n  padding: 10px;\n  > div {\n    margin: 2px 0;\n  }\n  &-title {\n    font-size: 14px;\n    font-weight: bold;\n    line-height: 20px;\n  }\n  .ant-progress {\n    line-height: 0;\n    &-bg {\n      height: 10px !important;\n    }\n    &-text {\n      font-size: 14px;\n    }\n  }\n  .ant-popover {\n    width: auto;\n  }\n  .ant-popover-content {\n    overflow: auto;\n    border-radius: 5px;\n    &::-webkit-scrollbar {\n      width: 4px;\n      height: 4px;\n    }\n    &::-webkit-scrollbar-track {\n      background-color: var(--var-prismer-color5);\n    }\n    &::-webkit-scrollbar-thumb {\n      border-radius: 3px;\n      background-color: #b2b2b2;\n    }\n  }\n  .ant-popover-inner {\n    font-size: 12px;\n    padding: 5px;\n    border-radius: 5px;\n    background-color: #b2b2b2;\n  }\n  .ant-popover-arrow {\n    &::before,\n    &::after {\n      background-color: #b2b2b2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
