// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cm-scroller {
  font-size: 0.0875rem;
}
.cm-scroller::-webkit-scrollbar {
  width: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/component/codeEditor/index.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAAE;EACE,QAAA;AAEJ","sourcesContent":[".cm-scroller {\n  font-size: 14px;\n  &::-webkit-scrollbar {\n    width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
