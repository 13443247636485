import React, { useState } from "react";
import { UserOutlined, RobotOutlined } from "@ant-design/icons";
import "./index.scss";

const OperationLog = () => {
  const [data, setData] = useState([
    {
      type: "User",
      content:
        "Requested code generation for user management module Requested code generation for user management module Requested code generation for user management module",
      time: "just now"
    },
    {
      type: "System",
      content: "Generated module structure and base classes",
      time: "1 min ago"
    }
  ]);

  return (
    <div className="operation-log">
      {data.map((d: any, idx: number) => {
        const { type } = d;

        return (
          <div className="operation-log-item" key={idx}>
            {type === "User" ? <UserOutlined /> : <RobotOutlined />}
            <div className="operation-log-item-main">
              <div className="operation-log-item-main-left">
                <div className="operation-log-item-type">{d.type}</div>
                <p className="operation-log-item-content">{d.content}</p>
              </div>
              <span className="operation-log-item-time">{d.time}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OperationLog;
