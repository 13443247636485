/*
 *公用常量
 */
const Constant = {
  HTTP_STATUS: {
    SUCCESS: 200,
    NO_VISIT: 401,
    NO_DATA: 301
  }
};

export default Constant;
