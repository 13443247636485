const Utils = {
  // 存储localStorage
  setLocalStorage: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },
  // 获取localStorage
  getLocalStorage: (key: string) => {
    return localStorage.getItem(key);
  },
  // 删除localStorage
  removeLocalStorage: (key: string) => {
    if (key === "all") {
      localStorage.clear();
    } else {
      localStorage.removeItem(key);
    }
  }
};

export default Utils;
