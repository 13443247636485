// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.operation-log {
  width: 100%;
}
.operation-log-item {
  width: 100%;
  padding: 0.09375rem;
  background: #f4ede6;
  border: 0.00625rem solid rgb(223, 212, 201);
  border-radius: 0.0625rem;
  margin-bottom: 0.0625rem;
  display: flex;
  align-items: center;
}
.operation-log-item .anticon {
  font-size: 0.1625rem;
  margin-right: 0.09375rem;
  color: #a7b0b3;
}
.operation-log-item-main {
  max-width: 90%;
  margin-right: 0.0625rem;
  position: relative;
}
.operation-log-item-type {
  font-size: 0.1rem;
  font-weight: bold;
}
.operation-log-item-content {
  font-size: 0.0875rem;
  color: #5d646c;
}
.operation-log-item-time {
  font-size: 0.075rem;
  color: #8c8c8c;
  position: absolute;
  top: 0.03125rem;
  right: -0.125rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/component/operationLog/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,2CAAA;EACA,wBAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AADI;EACE,oBAAA;EACA,wBAAA;EACA,cAAA;AAGN;AADI;EACE,cAAA;EACA,uBAAA;EACA,kBAAA;AAGN;AADI;EACE,iBAAA;EACA,iBAAA;AAGN;AADI;EACE,oBAAA;EACA,cAAA;AAGN;AADI;EACE,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AAGN","sourcesContent":[".operation-log {\n  width: 100%;\n  &-item {\n    width: 100%;\n    padding: 15px;\n    background: #f4ede6;\n    border: 1px solid rgb(223, 212, 201);\n    border-radius: 10px;\n    margin-bottom: 10px;\n    display: flex;\n    align-items: center;\n    .anticon {\n      font-size: 26px;\n      margin-right: 15px;\n      color: #a7b0b3;\n    }\n    &-main {\n      max-width: 90%;\n      margin-right: 10px;\n      position: relative;\n    }\n    &-type {\n      font-size: 16px;\n      font-weight: bold;\n    }\n    &-content {\n      font-size: 14px;\n      color: #5d646c;\n    }\n    &-time {\n      font-size: 12px;\n      color: #8c8c8c;\n      position: absolute;\n      top: 5px;\n      right: -20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
