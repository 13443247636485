import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Ai {
  id: string;
  progressData: any;
  fileNode: any;
}

const initialState: Ai | [] = [];

const aiDataSlice = createSlice({
  name: "aiData",
  initialState,
  reducers: {
    setAiData: (state: Ai[] | [], action: PayloadAction<any>) => {
      state = [...state, action.payload];
      console.log("++++++ aiData +++++", state);
    }
  }
});

export const { setAiData } = aiDataSlice.actions;
export default aiDataSlice.reducer;
