import { Utils, Constant } from "@/common";
// 请求流式数据公用方法（包含中断请求）
const fetchFun = (
  url: string,
  bodyData: any,
  streamCallBack: (data: any) => void,
  errorCallBack: () => void,
  doneCallBack: () => void,
  signal?: AbortSignal // 允许传入 signal 以便支持取消请求
) => {
  const controller = new AbortController(); // 创建新的 AbortController
  const fetchSignal = signal || controller.signal; // 如果传入了 signal，使用传入的 signal，否则使用新的 controller

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      uid: Utils.getLocalStorage("uid") || ""
    },
    body: JSON.stringify(bodyData),
    signal: fetchSignal
  })
    .then((res: any) => {
      const { status } = res;
      if (status === Constant.HTTP_STATUS.NO_VISIT) {
        window.location.href = "/access-denied";
        return;
      }

      const reader = res.body.getReader();
      const decoder = new TextDecoder();
      let buffer = ""; // 临时缓存解码数据
      let jsonBuffer = ""; // 用于存储不完整的 JSON 数据块

      // 解析并处理流数据
      const processStream = () => {
        reader
          .read()
          .then((data: any) => {
            const { done, value } = data;

            // 检查请求是否被取消
            if (done) {
              doneCallBack();
              return;
            }

            // 解码字节流
            buffer += decoder.decode(value, { stream: true });

            // 处理缓存的字节流
            let parts = buffer.split("\n\n");
            if (parts.length > 1) {
              processParts(parts);
            }

            // 保留未处理的部分，继续等待后续数据
            buffer = parts[parts.length - 1];

            // 继续读取下一块数据
            if (!fetchSignal.aborted) {
              // 监听外部传入的 signal
              processStream();
            } else {
              reader.cancel(); // 如果请求被取消，停止读取流
              console.log("Request canceled by AbortSignal");
            }
          })
          .catch((err: any) => {
            if (err.name === "AbortError") {
              // 如果是由于 AbortSignal 引发的错误
              console.log("Request canceled by AbortSignal");
            } else {
              errorCallBack();
            }
          });
      };

      // 处理每个解析块
      const processParts = (parts: string[]) => {
        parts.forEach((part) => {
          if (part.trim()) {
            try {
              const jsonString = extractJson(part);
              const curStreamData = JSON.parse(jsonString);
              streamCallBack(curStreamData);
            } catch (e) {
              jsonBuffer += part; // 解析失败，缓存
            }
          }
        });

        // 尝试解析缓存中的数据
        if (jsonBuffer) {
          try {
            const curStreamData = JSON.parse(jsonBuffer);
            streamCallBack(curStreamData);
            jsonBuffer = ""; // 解析成功后清空缓存
          } catch (e) {
            // 继续等待更多数据来解析
          }
        }
      };

      // 提取 JSON 字符串
      const extractJson = (part: string) => {
        return part.substring(part.indexOf("{"), part.lastIndexOf("}") + 1);
      };

      // 启动流处理
      processStream();
    })
    .catch((err) => {
      console.log(22222, err);
      if (err.name === "AbortError") {
        console.log("Request canceled by AbortSignal");
      } else {
        errorCallBack();
      }
    });

  // 返回 controller 以便外部可以取消请求
  return controller;
};

// 树形结构排序（有children的按照原顺序排在前面）
const sortData = (data: any) => {
  if (!data) return [];
  return data.sort((a: any, b: any) => {
    if (a.children && b.children) {
      return 0;
    }
    if (a.children) {
      return -1;
    }
    if (b.children) {
      return 1;
    }
    return 0;
  });
};

const updateProgressData = (defaultData: any, update: any) => {
  return defaultData.map((item: any) => {
    if (item.type === update.type) {
      return {
        ...item,
        progress: update.progress,
        currentContent: update.current_content
      };
    }
    return item;
  });
};

export default { fetchFun, sortData, updateProgressData };
