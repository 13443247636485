import React, { useState, useEffect } from "react";
// import { Outlet, useNavigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { Utils } from "@/common";
import { RouterModel, Routes } from "./routes";
import "./App.css";
import logo from "@/assets/icons/logo.png";

const { Header, Content } = Layout;

const App: React.FC = () => {
  const menuItems = [
    {
      key: "home",
      label: "home"
    }
    // {
    //   key: "history",
    //   label: "history"
    // }
  ];
  const noHeaderRouter = ["/access-denied", "/enter-app"];
  const location = useLocation();
  const { pathname, search } = location;
  const [hideHeader, setHideHeader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const uid = searchParams.get("uid");

    setHideHeader(noHeaderRouter.includes(pathname));
    if (!uid || uid.length === 0) {
      navigate("/access-denied");
    } else {
      Utils.setLocalStorage("uid", uid);
    }
  }, [pathname, search]);

  return (
    <div className="app">
      <Layout>
        {!hideHeader && (
          <Header className="app-header">
            <img className="app-logo" src={logo} />
            <Menu defaultSelectedKeys={["home"]} items={menuItems} />
          </Header>
        )}
        <Content>
          <RouterModel />
        </Content>
      </Layout>
    </div>
  );
};

export default App;
