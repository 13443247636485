// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  p {
    padding: 0;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  ul {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    padding: 0;
  }
}
:root {
  --var-prismer-chatbot: #98b6b1;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,mCAAmC;EACnC,kCAAkC;EAClC;;;;;;;;;IASE,UAAU;IACV,SAAS;IACT,qBAAqB;IACrB,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;EACtB;EACA;IACE,gBAAgB;IAChB,uBAAuB;IACvB,SAAS;IACT,UAAU;EACZ;AACF;AACA;EACE,8BAA8B;AAChC","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: Roboto;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6,\n  ul,\n  ol,\n  p {\n    padding: 0;\n    margin: 0;\n    margin-block-start: 0;\n    margin-block-end: 0;\n    margin-inline-start: 0;\n    margin-inline-end: 0;\n  }\n  ul {\n    list-style: none;\n    padding-inline-start: 0;\n    margin: 0;\n    padding: 0;\n  }\n}\n:root {\n  --var-prismer-chatbot: #98b6b1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
