import React, { useState, useEffect } from "react";
import { Progress, Tooltip, Popover } from "antd";
import { ColorMap, ProgressData } from "./config";
import "./index.scss";

const ProgressBar = (props: any) => {
  const { data } = props;
  const [progressData, setProgressData] = useState<ProgressData[]>([]);

  useEffect(() => {
    setProgressData(data);
  }, [data]);

  return (
    <div className="progress">
      {progressData.map((data, idx) => (
        <div key={idx}>
          <span className="progress-title">{data.type} Generation</span>
          {data.currentContent !== "" && data.progress !== 0 ? (
            <Popover
              content={`Loading: ${data.currentContent}`}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            >
              <Progress
                percent={Math.round(data.progress)}
                status="active"
                strokeColor={ColorMap[data.type]}
              />
            </Popover>
          ) : (
            <Progress
              percent={Math.round(data.progress)}
              status="active"
              strokeColor={ColorMap[data.type]}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
