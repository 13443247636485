import React, { useState, useRef } from "react";
import { FloatButton, Drawer } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import { Api, Utils } from "@/common";
import {
  ChatMessage,
  TextEditor,
  ContainerProgress,
  ContainerCode,
  CodeEditor
} from "./component";
import CommonFun from "./component/common";
import { AiResponseType } from "./component/config";
import classnames from "classnames";
import "./index.scss";
import logo from "@/assets/icons/logo_name.png";
import loading from "@/assets/icons/loading.gif";

const Home = () => {
  const [showChatbot, setShowChatbot] = useState(true);
  const [userData, setUserData] = useState(null as any);
  const [containerData, setContainerData] = useState(null);
  const [showContainerDataProgress, setShowContainerDataProgress] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTestLoading, setIsTestLoading] = useState(false);
  const [testData, setTestData] = useState<any>(null);
  const [testProgressData, setTestProgressData] = useState<any>(null);
  const [isShowNodeCode, setIsShowNodeCode] = useState(false);
  const [nodeCodeInfo, setNodeCodeInfo] = useState<any>({});
  const [testMarkdownData, setTestMarkdownData] = useState("");
  const currentController = useRef<AbortController | null>(null);

  const handleSend = (content: any) => {
    setUserData(content);
  };

  const handleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  const getTestData = (id: string) => {
    const reqData = {
      generate_trace_id: id,
      model: Utils.getLocalStorage("model")
    };

    // 创建一个新的 AbortController 来控制当前请求
    const controller = new AbortController();
    currentController.current = controller;

    CommonFun.fetchFun(
      Api.test,
      reqData,
      (res: any) => {
        handleAiResponse(res);
      },
      () => {
        setIsTestLoading(false);
      },
      () => {
        setIsTestLoading(false);
      },
      controller.signal
    );
  };

  // 处理大模型返回的数据
  const handleAiResponse = (data: any) => {
    const { type, answer } = data;

    if (type === AiResponseType.test) {
      setTestMarkdownData((pre) => pre + answer);
    } else if (type === AiResponseType.project) {
      const { Ret, Progress, CurrentFile } = answer;
      setTestData(Ret);
      setTestProgressData({
        progress: Progress,
        currentContent: CurrentFile
      });
    }
  };

  const currentChatDataCallBack = (
    data: any,
    isLoading: boolean,
    showLoading: boolean
  ) => {
    showLoading && setIsLoading(isLoading);
    setContainerData(data);
    setShowContainerDataProgress(true);

    // code生成完成获取test进度
    if (!isLoading) {
      setIsTestLoading(true);
      getTestData(data.id);
    }
  };

  const showNodeCode = (data: any) => {
    const { code } = data;
    if (code.length === 0 || isLoading) return;

    setIsShowNodeCode(true);
    setNodeCodeInfo(data);
  };

  // 触发fix code则暂停上一次的test
  const fixCallBack = (isFix: boolean) => {
    if (!isFix) return;

    if (currentController.current) {
      currentController.current.abort();
    }
  };

  return (
    <div className="home" id="home">
      <div
        className={classnames("home-container", {
          "home-container-full": !showChatbot
        })}
      >
        {containerData ? (
          <div className="container-data">
            <ContainerProgress
              isShow={showContainerDataProgress}
              data={containerData}
              testData={testProgressData}
              callBack={(bol: boolean) => setShowContainerDataProgress(bol)}
              showNodeCode={showNodeCode}
              className={isLoading ? "progress-loading" : ""}
            />
            <div
              className={classnames("container-data-code", {
                "container-data-code-full": !showContainerDataProgress,
                "container-data-code-loading": isLoading
              })}
            >
              <ContainerCode
                className={classnames("container-data-code-main", {
                  "container-data-code-main-show": !isLoading
                })}
                data={containerData}
                testData={testData}
                changeDataCallBack={currentChatDataCallBack}
                isLoading={loading}
                fixCallBack={fixCallBack}
              />
              <img
                className={classnames("loading", {
                  "loading-hide": !isLoading
                })}
                src={loading}
              />
            </div>
          </div>
        ) : (
          <div className="home-container-default">
            <div>
              <img src={logo} className="home-logo" />
              <p className="home-title">Hierarchical Code Generation System</p>
            </div>
          </div>
        )}
      </div>
      <div
        className={classnames("home-chatbot", {
          "home-chatbot-full": showChatbot
        })}
      >
        <div className="home-chatbot-main">
          {userData ? (
            <ChatMessage
              userData={userData}
              currentDataCallBack={currentChatDataCallBack}
              testData={{ data: testMarkdownData, loading: isTestLoading }}
            />
          ) : (
            <div className="home-chatbot-tips">
              <p>
                Hello! I am your smart assistant, ready to help you at any time.
                You can
                <span className="high-color">
                  generate a complete project code through me
                </span>
                ! I can create a project structure for you based on any of the
                following:
              </p>
              <ul>
                <li>
                  1.<span className="font-weight">Send a text message</span>
                  <p>
                    Tell me your requirements and functional descriptions, I
                    will help you organize the requirements and generate a code
                    framework.
                  </p>
                </li>
                <li>
                  2.
                  <span className="font-weight">Upload pictures or files</span>
                  <p>
                    Share relevant files or pictures, I will generate
                    corresponding code or project modules based on the content.
                  </p>
                </li>
                <li>
                  3.
                  <span className="font-weight">
                    Provide links or code modules
                  </span>
                  <p>
                    Share the webpages or resource links or code modules you
                    found, I will parse the information and help you generate
                    code implementation.
                  </p>
                </li>
              </ul>
            </div>
          )}
          <TextEditor
            onSend={handleSend}
            isLoading={isLoading || isTestLoading}
          />
        </div>
      </div>
      <FloatButton
        className="float-btn"
        tooltip={<div>{showChatbot ? "Hide Me" : "Chat With PRISMER"}</div>}
        icon={<CommentOutlined />}
        onClick={handleChatbot}
      />
      <Drawer
        className="node-code-box"
        title={nodeCodeInfo.title}
        placement="right"
        onClose={() => setIsShowNodeCode(false)}
        open={isShowNodeCode}
        width="100%"
        mask={false}
        getContainer={() =>
          document.getElementById("parent-drawer") || document.body
        }
        rootStyle={{ zIndex: 9999 }}
      >
        <CodeEditor code={nodeCodeInfo.code} />
      </Drawer>
    </div>
  );
};

export default Home;
