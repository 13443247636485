// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/font/roboto.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Roboto;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

.app {
  .app-header {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.3125rem;
  }
  .app-logo {
    width: 0.225rem;
    height: 0.225rem;
  }
  .ant-menu {
    display: flex;
    background: #e6e4e1;
    align-items: center;
    font-family: Roboto;
    .ant-menu-item {
      text-align: center;
      width: auto;
      height: 0.21875rem;
      line-height: 0.21875rem;
      margin-left: 0.125rem;
    }
    .ant-menu-item-selected {
      color: rgba(0, 0, 0, 0.7);
      font-weight: bold;
      background-color: #b4ada9;
      font-size: 0.1125rem;
    }
  }
}
.ant-layout-header {
  height: 0.4rem;
  color: rgba(0, 0, 0, 0.88);
  line-height: 0.4rem;
  background: #e6e4e1;
}
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: none;
}
.ant-select {
  font-family: Roboto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,+DAAuD;AACzD;;AAEA;EACE;IACE,gBAAgB;IAChB,MAAM;IACN,UAAU;IACV,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,oBAAe;EACjB;EACA;IACE,eAAW;IACX,gBAAY;EACd;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB;MACE,kBAAkB;MAClB,WAAW;MACX,kBAAY;MACZ,uBAAiB;MACjB,qBAAiB;IACnB;IACA;MACE,yBAAyB;MACzB,iBAAiB;MACjB,yBAAyB;MACzB,oBAAe;IACjB;EACF;AACF;AACA;EACE,cAAY;EACZ,0BAA0B;EAC1B,mBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,mBAAmB;AACrB","sourcesContent":["@font-face {\n  font-family: Roboto;\n  src: url(\"./assets/font/roboto.ttf\") format(\"truetype\");\n}\n\n.app {\n  .app-header {\n    position: sticky;\n    top: 0;\n    z-index: 1;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 50px;\n  }\n  .app-logo {\n    width: 36px;\n    height: 36px;\n  }\n  .ant-menu {\n    display: flex;\n    background: #e6e4e1;\n    align-items: center;\n    font-family: Roboto;\n    .ant-menu-item {\n      text-align: center;\n      width: auto;\n      height: 35px;\n      line-height: 35px;\n      margin-left: 20px;\n    }\n    .ant-menu-item-selected {\n      color: rgba(0, 0, 0, 0.7);\n      font-weight: bold;\n      background-color: #b4ada9;\n      font-size: 18px;\n    }\n  }\n}\n.ant-layout-header {\n  height: 64px;\n  color: rgba(0, 0, 0, 0.88);\n  line-height: 64px;\n  background: #e6e4e1;\n}\n.ant-menu-light.ant-menu-root.ant-menu-vertical {\n  border-inline-end: none;\n}\n.ant-select {\n  font-family: Roboto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
