import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { python } from "@codemirror/lang-python";
import "./index.scss";

const CodeEditor = (props: any) => {
  const { code } = props;

  return (
    <CodeMirror
      value={code}
      theme={vscodeDark}
      editable={false}
      extensions={[python()]}
    />
  );
};

export default CodeEditor;
