import React, { useEffect, useState } from "react";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { FileTree, ProgressBar } from "../../component";
import classnames from "classnames";
import { CodeStatus } from "./config";
import CommonFun from "../common";
import "./index.scss";

const ContainerProgress = (props: any) => {
  const { isShow, callBack, data, className, testData, showNodeCode } = props;
  const { progressData, fileNode } = data;
  const [showContainerDataProgress, setShowContainerDataProgress] =
    useState(isShow);
  const [fileTreeData, setFileTreeData] = useState<any>([]);
  const [allProgressData, setAllProgressData] = useState(progressData);

  const processData = (data: any) => {
    let uniqueKeyCounter = 0;

    // 生成唯一key
    const generateUniqueKey = () => `key-${uniqueKeyCounter++}`;

    // 需要过滤的文件扩展名正则
    const fileExtensionRegex = /\.(png|jpg|pdf|txt)$/i;

    // 判断节点是否需要被过滤（叶子节点或包含不显示的文件扩展名）
    const shouldFilterNode = (node: any) => {
      return fileExtensionRegex.test(node.title) || node.title === "";
    };

    // 处理节点
    const processNode = (
      node: any,
      parentPath: string = "",
      level: number = 0
    ) => {
      // 如果是叶子节点且需要过滤，直接返回 null
      if (shouldFilterNode(node)) {
        return null;
      }

      // 初始化新节点
      const newNode: any = {
        title: node.title,
        isLeaf: !node.children, // 判断是否是叶子节点
        status: node.status,
        key: generateUniqueKey(),
        code: node.code,
        level: level,
        path:
          parentPath === ""
            ? node.children
              ? `${node.title}/`
              : ``
            : `${parentPath}${node.title}`
      };

      // 如果有子节点，处理子节点
      if (node.children && Array.isArray(node.children)) {
        // 递归处理子节点，过滤掉无效节点
        const processedChildren = node.children
          .map((child: any) => processNode(child, newNode.path, level + 1))
          .filter((child: any) => child !== null);

        newNode.children = processedChildren;

        // 根据子节点状态更新父节点状态
        const childStatuses = processedChildren.map(
          (child: any) => child.status
        );
        newNode.status = determineParentStatus(childStatuses);
      }

      return newNode;
    };

    // 确定父节点状态的函数
    const determineParentStatus = (childStatuses: number[]) => {
      if (childStatuses.includes(CodeStatus.isWaiting)) {
        return CodeStatus.isWaiting; // 优先级最高：有子节点等待中
      }
      if (childStatuses.every((status) => status === CodeStatus.isDone)) {
        return CodeStatus.isDone; // 所有子节点已完成
      }
      if (childStatuses.includes(CodeStatus.isError)) {
        return CodeStatus.isError; // 有错误子节点
      }
      return CodeStatus.isUnknown; // 默认状态
    };

    // 处理所有根节点并过滤无效节点
    return data
      .map((node: any) => processNode(node, ""))
      .filter((node: any) => node !== null);
  };

  const handleContainerDataProgress = () => {
    setShowContainerDataProgress(!showContainerDataProgress);
    callBack(!showContainerDataProgress);
  };

  const seleteTreeNodeCallBack = (nodeData: any) => {
    const { isLeaf } = nodeData;
    isLeaf && showNodeCode(nodeData);
  };

  useEffect(() => {
    if (!testData) {
      setAllProgressData(progressData);
    } else {
      setAllProgressData((prevData: any) => {
        const updatedData = prevData.some((item: any) => item.type === "Test")
          ? prevData.map((item: any) =>
              item.type === "Test" ? { type: "Test", ...testData } : item
            )
          : [...prevData, { type: "Test", ...testData }];

        return updatedData;
      });
    }
  }, [testData, progressData]);

  useEffect(() => {
    if (!fileNode) return;
    setFileTreeData(processData(CommonFun.sortData(fileNode)));
  }, [fileNode]);

  return (
    <div
      className={classnames(`container-data-progress ${className}`, {
        "container-data-progress-show": showContainerDataProgress
      })}
    >
      <span onClick={handleContainerDataProgress}>
        {showContainerDataProgress ? (
          <DoubleLeftOutlined />
        ) : (
          <DoubleRightOutlined />
        )}
      </span>
      <div className="container-data-progress-main">
        <ProgressBar data={allProgressData} />
        <div className="container-data-progress-tree">
          <h4>Project Structure</h4>
          <FileTree
            className="progress-file-tree"
            data={fileTreeData}
            seleteTreeNodeCallBack={seleteTreeNodeCallBack}
          />
        </div>
      </div>
    </div>
  );
};

export default ContainerProgress;
