interface Status {
  isWaiting: number;
  isDone: number;
  isError: number;
  isUnknown: number;
}

const CodeStatus: Status = {
  isWaiting: 1,
  isDone: 2,
  isError: 3,
  isUnknown: 0
};

export { CodeStatus };
