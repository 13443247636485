// 定义模块类型
type ModuleType = "Module" | "File" | "Class" | "Method" | "Test";

// 定义进度数据类型
export interface ProgressData {
  type: ModuleType;
  progress: number;
  currentContent?: string;
}

// 定义颜色映射
const ColorMap: Record<ModuleType, string> = {
  Module: "#A7B4B7",
  File: "#C4B2A4",
  Class: "#B6A6A6",
  Method: "#D2C3A7",
  Test: "#B4C0A5"
};

export { ColorMap };
