import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { Home, AccessDenied, EnterApp } from "@/pages";

const Routes: any = [
  // 默认home页重定向
  {
    path: "",
    element: <Navigate to="/enter-app" />,
    key: ""
  },
  {
    path: "/enter-app",
    element: <EnterApp />,
    key: "enterApp"
  },
  {
    path: "/home",
    element: <Home />,
    key: "home"
  },
  {
    path: "/access-denied",
    element: <AccessDenied />,
    key: "accessDenied"
  }
];

const AppRoutes = () => {
  return useRoutes(Routes);
};

const RouterModel = () => {
  return <AppRoutes />;
};

export { Routes, RouterModel };
