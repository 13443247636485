import React, { useState, useEffect, useCallback } from "react";
import { Tree, message, Spin, Input, Popover } from "antd";
import {
  FileFilled,
  FolderFilled,
  SnippetsFilled,
  AppstoreFilled,
  CodepenSquareFilled,
  OneToOneOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  FieldTimeOutlined,
  TagsFilled
} from "@ant-design/icons";
import type { DataNode, TreeProps } from "antd/es/tree";
import classnames from "classnames";
import { TreeData } from "./config";
import "./index.scss";
import loadingIcon from "@/assets/icons/tree_loading.gif";

interface FileNode extends DataNode {
  key: string;
  title: string;
  isLeaf: boolean;
  status: 0 | 1 | 2 | 3;
  children?: FileNode[];
  code?: string;
  level?: number;
}

const FileTree = (props: TreeData) => {
  const {
    showStatusAndBtn = true,
    data,
    seleteTreeNodeCallBack,
    isShowLeafIcon = true,
    className
  } = props;
  const [treeData, setTreeData] = useState<FileNode[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editingValue, setEditingValue] = useState("");
  const [expandedKeys, setExpandedKeys] = useState<any>([]);

  const getFirstKeys = (data: any) => {
    const keys: any = [];
    const firstNode = data[0];
    keys.push(firstNode.key);
    if (firstNode.children) {
      keys.push(...getFirstKeys(firstNode.children));
    }
    return keys;
  };

  const getAllKeys = (data: any) => {
    const keys: any = [];

    const traverse = (node: any) => {
      if (node.key) {
        keys.push(node.key);
      }

      if (node.children && Array.isArray(node.children)) {
        node.children.forEach((child: any) => traverse(child));
      }
    };

    data.forEach((node: any) => traverse(node));
    return keys;
  };

  const fetchFileTree = (data: any) => {
    try {
      setTreeData(data);
      setExpandedKeys(isShowLeafIcon ? getAllKeys(data) : getFirstKeys(data));
    } catch (error) {
      // message.error("Failed to fetch file tree");
    } finally {
      setLoading(false);
    }
  };

  const renderTitle = (node: FileNode) => {
    const { key, title, status } = node;
    let icon;

    switch (status) {
      case 0:
        icon = <FieldTimeOutlined />;
        break;
      case 1:
        icon = <LoadingOutlined spin />;
        break;
      case 2:
        icon = <CheckCircleTwoTone twoToneColor="#7CB083" />;
        break;
      case 3:
        icon = <ExclamationCircleTwoTone twoToneColor="#D47D7D" />;
        break;
    }

    if (editingKey === key) {
      return (
        <Input
          value={editingValue}
          onChange={(e) => setEditingValue(e.target.value)}
          onPressEnter={() => handleRename(node)}
          onBlur={() => handleRename(node)}
          style={{ width: "calc(100% - 50px)" }}
          autoFocus
        />
      );
    }

    return (
      <>
        <span className="tree-title-name">
          <span className="title-text">{title}</span>
        </span>
        {showStatusAndBtn && (
          <>
            {icon}

            {/* <span className="handle-btns">
              <EditOutlined onClick={() => handleEdit(node)} />
              <DeleteOutlined onClick={() => handleDelete(node)} />
              {!isLeaf && <PlusOutlined onClick={() => handleAddFile(node)} />}
            </span> */}
          </>
        )}
      </>
    );
  };

  const handleEdit = (node: FileNode) => {
    setEditingKey(node.key);
    setEditingValue(node.title);
  };

  const handleRename = async (node: FileNode) => {
    if (editingValue.trim() === "" || editingValue === node.title) {
      setEditingKey(null);
      return;
    }

    try {
      // 这里应该是实际的API调用
      // await api.renameFile(node.key, editingValue);

      const updateTreeData = (data: FileNode[]): FileNode[] => {
        return data.map((item) => {
          if (item.key === node.key) {
            return { ...item, title: editingValue };
          }
          if (item.children) {
            return { ...item, children: updateTreeData(item.children) };
          }
          return item;
        });
      };

      setTreeData(updateTreeData(treeData));
      message.success("File renamed successfully");
    } catch (error) {
      message.error("Failed to rename file");
    } finally {
      setEditingKey(null);
    }
  };

  const handleDelete = async (node: FileNode) => {
    try {
      // 这里应该是实际的API调用
      // await api.deleteFile(node.key);

      const deleteNode = (data: FileNode[]): FileNode[] => {
        return data.filter((item) => {
          if (item.key === node.key) {
            return false;
          }
          if (item.children) {
            item.children = deleteNode(item.children);
          }
          return true;
        });
      };

      setTreeData(deleteNode(treeData));
      message.success("File deleted successfully");
    } catch (error) {
      message.error("Failed to delete file");
    }
  };

  const handleAddFile = async (node: FileNode) => {
    const newFileName = "New File";
    try {
      // 这里应该是实际的API调用
      // const newFileKey = await api.addFile(node.key, newFileName);

      const newFileKey = Date.now().toString(); // 临时使用时间戳作为key

      const addNode = (data: FileNode[]): FileNode[] => {
        return data.map((item) => {
          if (item.key === node.key) {
            const newChild: FileNode = {
              key: newFileKey,
              title: newFileName,
              isLeaf: true,
              status: 2
            };
            return {
              ...item,
              children: [...(item.children || []), newChild]
            };
          }
          if (item.children) {
            return { ...item, children: addNode(item.children) };
          }
          return item;
        });
      };

      setTreeData(addNode(treeData));
      message.success("New file added successfully");
    } catch (error) {
      message.error("Failed to add new file");
    }
  };

  const onSelect: TreeProps["onSelect"] = (selectedKeys: any, { node }) => {
    const key = node.key;

    seleteTreeNodeCallBack && seleteTreeNodeCallBack(node);
    if (expandedKeys.includes(key)) {
      setExpandedKeys(expandedKeys.filter((k: any) => k !== key));
    } else {
      setExpandedKeys((pre: string[]) => [...pre, key]);
    }
  };

  const getIcon = useCallback(
    (props: any) => {
      if (isShowLeafIcon) {
        return props.isLeaf ? <FileFilled /> : <FolderFilled />;
      }

      const iconMap: Record<string, React.ReactNode> = {
        isManage: <AppstoreFilled />,
        isFile: <SnippetsFilled />,
        isService: <CodepenSquareFilled />,
        isMethod: <OneToOneOutlined />,
        isClass: <TagsFilled />
      };

      // 返回第一个匹配的 icon
      for (const key of Object.keys(iconMap)) {
        if (props[key]) {
          return iconMap[key];
        }
      }

      return null; // 默认返回值（如果没有匹配的条件）
    },
    [isShowLeafIcon]
  );

  useEffect(() => {
    fetchFileTree(data);
  }, [data]);

  return (
    <div
      className={classnames(`file-tree ${className}`, {
        "file-tree-loading": loading
      })}
    >
      {!loading ? (
        <Tree
          showLine
          showIcon
          expandedKeys={expandedKeys}
          onSelect={onSelect}
          treeData={treeData}
          titleRender={renderTitle}
          icon={getIcon}
        />
      ) : (
        <img src={loadingIcon} />
      )}
    </div>
  );
};

export default FileTree;
