// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown {
  font-family: Roboto-Regular;
  font-size: 0.075rem;
  line-height: 0.1375rem;
}
.markdown h1 {
  font-size: 0.1rem;
}
.markdown h2 {
  font-size: 0.0875rem;
}
.markdown h3 {
  font-size: 0.075rem;
  margin: 0.03125rem 0;
}
.markdown ol {
  padding-inline-start: 0.125rem;
}
.markdown li {
  list-style: none;
}
.markdown table {
  min-width: 2.5rem;
  border: 0.00625rem solid #000000;
  border-collapse: collapse;
}
.markdown th,
.markdown td {
  width: 0.625rem;
  border: 0.00625rem solid #000000;
  text-align: center;
}
.markdown pre {
  padding: 0.03125rem;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0.0375rem;
  overflow: auto;
}
.markdown pre div,
.markdown pre span {
  background: none !important;
}
.markdown pre > div::-webkit-scrollbar {
  width: 0.025rem;
  height: 0.025rem;
}
.markdown pre > div::-webkit-scrollbar-track {
  background-color: var(--var-prismer-color5);
}
.markdown pre > div::-webkit-scrollbar-thumb {
  border-radius: 0.01875rem;
  background-color: #b2b2b2;
}
.markdown pre::-webkit-scrollbar {
  width: 0.025rem;
  height: 0.025rem;
}
.markdown pre::-webkit-scrollbar-track {
  background-color: var(--var-prismer-color5);
}
.markdown pre::-webkit-scrollbar-thumb {
  border-radius: 0.01875rem;
  background-color: #b2b2b2;
}
.markdown hr {
  border: 0.00063rem solid rgba(50, 92, 239, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/home/component/markdown/index.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,mBAAA;EACA,sBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AAAE;EACE,oBAAA;AAEJ;AAAE;EACE,mBAAA;EACA,oBAAA;AAEJ;AAAE;EACE,8BAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,iBAAA;EACA,gCAAA;EACA,yBAAA;AAEJ;AAAE;;EAEE,eAAA;EACA,gCAAA;EACA,kBAAA;AAEJ;AAAE;EACE,mBAAA;EACA,oCAAA;EACA,wBAAA;EACA,cAAA;AAEJ;AADI;;EAEE,2BAAA;AAGN;AAAM;EACE,eAAA;EACA,gBAAA;AAER;AAAM;EACE,2CAAA;AAER;AAAM;EACE,yBAAA;EACA,yBAAA;AAER;AACI;EACE,eAAA;EACA,gBAAA;AACN;AACI;EACE,2CAAA;AACN;AACI;EACE,yBAAA;EACA,yBAAA;AACN;AAEE;EACE,+CAAA;AAAJ","sourcesContent":[".markdown {\n  font-family: Roboto-Regular;\n  font-size: 12px;\n  line-height: 22px;\n  h1 {\n    font-size: 16px;\n  }\n  h2 {\n    font-size: 14px;\n  }\n  h3 {\n    font-size: 12px;\n    margin: 5px 0;\n  }\n  ol {\n    padding-inline-start: 20px;\n  }\n  li {\n    list-style: none;\n  }\n  table {\n    min-width: 400px;\n    border: 1px solid #000000;\n    border-collapse: collapse;\n  }\n  th,\n  td {\n    width: 100px;\n    border: 1px solid #000000;\n    text-align: center;\n  }\n  pre {\n    padding: 5px;\n    background: rgba(255, 255, 255, 0.4);\n    border-radius: 6px;\n    overflow: auto;\n    div,\n    span {\n      background: none !important;\n    }\n    > div {\n      &::-webkit-scrollbar {\n        width: 4px;\n        height: 4px;\n      }\n      &::-webkit-scrollbar-track {\n        background-color: var(--var-prismer-color5);\n      }\n      &::-webkit-scrollbar-thumb {\n        border-radius: 3px;\n        background-color: #b2b2b2;\n      }\n    }\n    &::-webkit-scrollbar {\n      width: 4px;\n      height: 4px;\n    }\n    &::-webkit-scrollbar-track {\n      background-color: var(--var-prismer-color5);\n    }\n    &::-webkit-scrollbar-thumb {\n      border-radius: 3px;\n      background-color: #b2b2b2;\n    }\n  }\n  hr {\n    border: 0.1px solid rgba(50, 92, 239, 0.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
