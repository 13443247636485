import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button, Input, Upload, Popover, Select } from "antd";
import {
  UploadOutlined,
  CloseCircleOutlined,
  LinkOutlined,
  CodeOutlined,
  StopOutlined,
  SendOutlined,
  DownOutlined
} from "@ant-design/icons";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import classnames from "classnames";
import { Utils, Constant, SendGetRequest, Api } from "@/common";
import { EditorProps, ContentBlock } from "./config";
import "./index.scss";

const TextEditor = ({ onSend, isLoading }: EditorProps) => {
  const [content, setContent] = useState<ContentBlock[]>([
    { type: "text", content: "", id: "0" }
  ]);
  const [codeInput, setCodeInput] = useState("");
  const [linkInput, setLinkInput] = useState("");
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [openInsertCode, setOpenInsertCode] = useState(false);
  const [openInsertLink, setOpenInsertLink] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<ContentBlock[]>(content);
  const [open, setOpen] = useState(false); // 控制下拉状态
  const [options, setOptions] = useState<any>([]);
  const [defaultOption, setDefaultOption] = useState("");

  const updateContent = useCallback((newContent: ContentBlock[]) => {
    setContent(newContent);
    contentRef.current = newContent;
  }, []);

  const updateTextContent = useCallback(() => {
    if (editorRef.current) {
      const textContent = editorRef.current.innerText;
      updateContent([
        { type: "text", content: textContent, id: "0" },
        ...contentRef.current.filter((block) => block.type !== "text")
      ]);
      setShowPlaceholder(textContent.length === 0);
    }
  }, [updateContent]);

  const handleTextChange = useCallback(() => {
    setIsEmpty(false);
    updateTextContent();
  }, [updateTextContent]);

  const insertContent = useCallback(
    (newBlock: ContentBlock) => {
      updateContent([...contentRef.current, newBlock]);
    },
    [updateContent]
  );

  const handleFileUpload = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newBlock: ContentBlock = {
          type: file.type.startsWith("image/") ? "image" : "file",
          content: e.target?.result as string,
          id: Date.now().toString()
        };
        console.log("上传的文件信息：", file);
        insertContent(newBlock);
      };
      reader.readAsDataURL(file);
    },
    [insertContent]
  );

  const handleCodeInsertion = useCallback(() => {
    const formattedCode = hljs.highlightAuto(codeInput).value;
    const newBlock: ContentBlock = {
      type: "code",
      content: formattedCode,
      id: Date.now().toString()
    };
    insertContent(newBlock);
    setOpenInsertCode(false);
    setCodeInput("");
  }, [codeInput, insertContent]);

  const handleLinkInsertion = useCallback(() => {
    const newBlock: ContentBlock = {
      type: "link",
      content: linkInput,
      id: Date.now().toString()
    };
    insertContent(newBlock);
    setOpenInsertLink(false);
    setLinkInput("");
  }, [linkInput, insertContent]);

  const handleDeleteBlock = useCallback(
    (id: string) => {
      updateContent(contentRef.current.filter((block) => block.id !== id));
    },
    [updateContent]
  );

  const send = useCallback(() => {
    updateTextContent();
    const currentContent = contentRef.current;
    const formattedContent = {
      links: currentContent
        .filter((block) => block.type === "link")
        .map((block) => block.content),
      imgs: currentContent
        .filter((block) => block.type === "image")
        .map((block) => block.id),
      docs: currentContent
        .filter((block) => block.type === "file")
        .map((block) => block.id),
      codes: currentContent
        .filter((block) => block.type === "code")
        .map((block) => block.content),
      text:
        currentContent.find((block) => block.type === "text")?.content || "",
      dom: editorRef.current ? editorRef.current.innerHTML : null
    };

    if (currentContent.length === 1 && currentContent[0].content === "") {
      setIsEmpty(true);
      return;
    }

    onSend(formattedContent);
    updateContent([{ type: "text", content: "", id: "0" }]);
    setShowPlaceholder(true);
    if (editorRef.current) {
      editorRef.current.innerHTML = "";
    }
  }, [onSend, updateTextContent, updateContent]);

  const stop = useCallback(() => {
    // Implement stop functionality here
  }, []);

  const handleBtn = useCallback(() => {
    if (isLoading) {
      stop();
    } else {
      send();
    }
  }, [isLoading, send, stop]);

  const codePopoverContent = (
    <div>
      <Input.TextArea
        rows={6}
        value={codeInput}
        onChange={(e) => setCodeInput(e.target.value)}
        placeholder="Paste or type your code here"
      />
      <Button
        type="primary"
        onClick={handleCodeInsertion}
        style={{ marginTop: "10px" }}
      >
        Insert
      </Button>
    </div>
  );

  const linkPopoverContent = (
    <div>
      <Input
        value={linkInput}
        onChange={(e) => setLinkInput(e.target.value)}
        placeholder="Enter or paste your link here"
      />
      <Button
        type="primary"
        onClick={handleLinkInsertion}
        style={{ marginTop: "10px" }}
      >
        Insert
      </Button>
    </div>
  );

  const onSelectModel = (value: string) => {
    Utils.setLocalStorage("model", value);
  };

  const formatModelOptions = (models: any) => {
    return models.map((model: any) => ({
      value: model,
      label: model
    }));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault();
        send();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [send]);

  useEffect(() => {
    if (
      editorRef.current &&
      content.length === 1 &&
      content[0].content === ""
    ) {
      editorRef.current.innerHTML = "";
    }
  }, [content]);

  useEffect(() => {
    SendGetRequest(Api.models, {}).then((res: any) => {
      if (!res) return;
      const o = res[0];

      setOptions(formatModelOptions(res));
      Utils.setLocalStorage("model", o);
      setDefaultOption(o);
    });
  }, []);

  return (
    <div className="text-editor">
      <div className="text-editor-area">
        <div className="text-editor-area-main">
          <div className="text-editor-area-text">
            <div
              className={classnames("text-editor-container", {
                "text-editor-empty": isEmpty
              })}
              ref={editorRef}
              contentEditable
              onInput={handleTextChange}
              onBlur={updateTextContent}
              suppressContentEditableWarning
              data-placeholder={
                showPlaceholder ? "Ask me whatever you like." : ""
              }
            >
              {content.map((block) => {
                if (block.type === "text") return null;

                return (
                  <span
                    key={block.id}
                    className={classnames("insert-box", {
                      "insert-box-code": block.type === "code"
                    })}
                  >
                    {block.type === "image" && (
                      <img
                        className="image-block"
                        src={block.content}
                        alt="Uploaded content"
                      />
                    )}
                    {block.type === "file" && <div>File: {block.id}</div>}
                    {block.type === "code" && (
                      <div className="code-block">
                        <pre>
                          <code
                            dangerouslySetInnerHTML={{ __html: block.content }}
                          />
                        </pre>
                      </div>
                    )}
                    {block.type === "link" && (
                      <a
                        href={block.content}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {block.content}
                      </a>
                    )}
                    {block.type !== "link" && (
                      <div
                        className="delete-button"
                        onClick={() => handleDeleteBlock(block.id)}
                      >
                        <CloseCircleOutlined />
                      </div>
                    )}
                  </span>
                );
              })}
            </div>
            {options.length > 0 && (
              <div className="change-model-select">
                <div
                  className={`change-model-select-arrow ${open ? "open" : ""}`}
                >
                  <DownOutlined />
                </div>
                <Select
                  defaultValue={defaultOption}
                  suffixIcon={null}
                  options={options}
                  dropdownStyle={{ left: "-0.07rem" }}
                  onDropdownVisibleChange={(visible) => setOpen(visible)}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  onSelect={onSelectModel}
                />
              </div>
            )}
          </div>
        </div>
        <span className="tips">
          Prismer AI may also make mistakes. Please verify important
          information.
        </span>
      </div>
      <div className="text-editor-toolbar">
        <div className="text-editor-toolbar-icons">
          <Upload
            directory
            beforeUpload={(file) => {
              handleFileUpload(file);
              return false;
            }}
            showUploadList={false}
            disabled={true}
          >
            <UploadOutlined />
          </Upload>
          <LinkOutlined />
          {/* <Popover
            className="insert-popover insert-link-popover"
            content={linkPopoverContent}
            open={openInsertLink}
            title="Insert Link"
            trigger="click"
            placement="left"
            onOpenChange={setOpenInsertLink}
            getPopupContainer={(trigger: any) => trigger.parentNode}
          >
            <LinkOutlined
              onClick={() => {
                setOpenInsertLink(true);
              }}
            />
          </Popover> */}
          <CodeOutlined />
          {/* <Popover
            className="insert-popover insert-code-popover"
            content={codePopoverContent}
            open={openInsertCode}
            title="Insert Code"
            trigger="click"
            placement="left"
            onOpenChange={setOpenInsertCode}
            getPopupContainer={(trigger: any) => trigger.parentNode}
          >
            <CodeOutlined
              onClick={() => {
                setOpenInsertCode(true);
              }}
            />
          </Popover> */}
        </div>
        <Button type="primary" onClick={handleBtn} className="send">
          {isLoading ? <StopOutlined /> : <SendOutlined />}
        </Button>
      </div>
    </div>
  );
};

export default TextEditor;
