import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ShrinkOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import { Api, Utils, Constant } from "@/common";
import { setAiData } from "@/common/store/slices/aiDataSlice";
import classnames from "classnames";
import { AiResponseType, defaultProgressData, ContentType } from "../config";
import CommonFun from "../common";
import { Markdown } from "../index";
import "./index.scss";
import loading from "@/assets/icons/chat_loading.gif";

const ChatMessage = (props: any) => {
  const { userData, currentDataCallBack, testData } = props;
  const [messages, setMessages] = useState<
    Array<{
      type: "user" | "ai";
      contentType: string;
      content?: any;
      loading?: boolean;
    }>
  >([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isCodeLoading, setIsCodeLoading] = useState<any>(null);
  const [curAiTaskId, setCurAiTaskId] = useState<any>(null);
  const [curAiCodeData, setCurAiCodeData] = useState<any>(null);
  const [curAiProgressData, setCurrentAiProgresData] =
    useState<any>(defaultProgressData);
  const MockResponse = `The left area of ​​the code given by PRISMER...`;
  const loadDom = `<img src="${loading}" class="chat-loading" />`;
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const [markdownData, setMarkdownData] = useState("");
  const [status, setStatus] = useState("");

  const removeTrailingDot = (str: string) => {
    if (str.endsWith(".")) {
      return str.slice(0, -1);
    }
    return str;
  };

  // code输出组件
  const ChatComponent = ({ markdownData, isDone }: any) => (
    <div className="cur-chat-dom">
      <Markdown markdownData={markdownData} />
      {isDone && <a>{MockResponse}</a>}
      <div className="inline-box">
        <span className="status">{removeTrailingDot(status)}</span>
        <img src={loading} className="chat-loading" />
      </div>
    </div>
  );

  // test输出组件
  const TestChatComponent = ({ codeData, testData, isLoading }: any) => {
    return (
      <div className="cur-chat-dom">
        <Markdown markdownData={codeData} />
        <a>{MockResponse}</a>
        <p className="test-tip">** The test information is as follows **</p>
        <div className="cur-chat-dom-test">
          <Markdown markdownData={testData} />
        </div>
        {isLoading && <img src={loading} className="chat-loading" />}
      </div>
    );
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // 完成消息状态更新
  const finalizeMessages = () => {
    setMessages((prev: any) => {
      const updatedMessages = prev.map((m: any) =>
        m.type === "ai" && m.loading
          ? { ...m, contentType: ContentType.answerDone }
          : m
      );
      return [...updatedMessages];
    });
  };

  const requestAiResponse = () => {
    setIsCodeLoading(true);

    const reqData = {
      input: userData.text,
      model: Utils.getLocalStorage("model")
    };
    CommonFun.fetchFun(
      Api.chat,
      reqData,
      (res: any) => {
        handleAiResponse(res);
      },
      () => {
        setIsCodeLoading(false);
      },
      () => {
        setIsCodeLoading(false);
        finalizeMessages();
      }
    );
  };

  // 处理大模型返回的数据
  const handleAiResponse = (data: any) => {
    const { type, answer, id } = data;

    setCurAiTaskId(id);
    if (type === AiResponseType.markdown) {
      setMarkdownData((pre) => pre + answer);
    } else if (type === AiResponseType.project) {
      setCurAiCodeData(answer);
    } else if (type === AiResponseType.progress) {
      setCurrentAiProgresData((pre: any) =>
        CommonFun.updateProgressData(pre, answer)
      );
    } else if (type === AiResponseType.status) {
      setStatus(answer);
    }
  };

  useEffect(() => {
    const { data } = testData;

    if (data.length === 0) return;

    setMessages((prev: any) => {
      const updatedMessages = prev.map((m: any) =>
        m.type === "ai" && m.loading
          ? { ...m, contentType: ContentType.test }
          : m
      );
      return [...updatedMessages];
    });
  }, [testData]);

  useEffect(() => {
    setMessages((prev: any) => {
      const a = prev.map((m: any) => {
        if (m.type === "ai" && m.loading) {
          return { ...m, contentType: ContentType.answerLoading };
        }
        return m;
      });
      return [...a];
    });
  }, [isCodeLoading, markdownData]);

  useEffect(() => {
    if (!userData) return;

    // 用户提问消息
    const createUserMessage = () => ({
      type: "user",
      contentType: ContentType.question,
      content: userData.dom
    });

    // 更新正在加载的消息
    const createLoadingAiMessage = () => ({
      type: "ai",
      contentType: ContentType.loading,
      content: loadDom,
      loading: true
    });

    // 更新已有消息的 loading 状态
    const updateAiMessages = (messages: any[]) =>
      messages.map((msg) => msg.type === "ai" && msg.loading && msg);

    // 合并新的用户消息和 AI 消息
    const addNewMessages = (messages: any[]) => {
      const updatedMessages = updateAiMessages(messages);
      return [
        ...updatedMessages,
        createUserMessage(),
        createLoadingAiMessage()
      ];
    };

    try {
      // 合并并更新消息
      setMessages((prevMessages) => addNewMessages(prevMessages));
      requestAiResponse();
    } catch (error) {
      // 发生错误时添加错误消息
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "ai",
          contentType: ContentType.answerDone,
          content: "Sorry, an error occurred, please try again"
        }
      ]);
    }
  }, [userData.dom]);

  useEffect(() => {
    if (!curAiCodeData || !curAiProgressData) return;

    const curAllData = {
      id: curAiTaskId,
      progressData: curAiProgressData,
      fileNode: curAiCodeData
    };

    dispatch(setAiData(curAllData));
    currentDataCallBack(curAllData, isCodeLoading, true);
  }, [curAiTaskId, curAiCodeData, curAiProgressData, isCodeLoading]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="chat-message">
      {messages.length > 0 &&
        messages.map((message, index) => {
          const { type, contentType, content } = message;
          const isUser = type === "user";
          const isAI = type === "ai";
          const isQuestionOrLoading =
            contentType === ContentType.question ||
            contentType === ContentType.loading;
          const isAnswerLoading = contentType === ContentType.answerLoading;
          const isAnswerDone = contentType === ContentType.answerDone;
          const isTest = contentType === ContentType.test;
          const shouldExpand = isUser && content.split("\n").length > 3;

          return (
            <div
              className={classnames("chat-message-item", {
                "chat-message-item-user": isUser,
                "chat-message-item-ai": isAI
              })}
              key={index}
            >
              <div className="chat-message-item-scroll">
                {/* user提问/接口pending时的loading */}
                {isQuestionOrLoading && (
                  <div
                    className={classnames("chat-message-item-text", {
                      "chat-message-item-text-expand": isUser && isExpanded
                    })}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                )}
                {/* code正在回答中打字机输出效果 */}
                {isAnswerLoading && (
                  <ChatComponent markdownData={markdownData} />
                )}
                {/* code回答完成 */}
                {isAnswerDone && (
                  <ChatComponent markdownData={markdownData} isDone />
                )}
                {/* test正在回答中打字机输出效果 */}
                {isTest && (
                  <TestChatComponent
                    codeData={markdownData}
                    testData={testData.data}
                    isLoading={testData.loading}
                  />
                )}
              </div>
              {/* 提问内容过多可展开收起 */}
              {shouldExpand && (
                <div className="toggle-button" onClick={handleToggle}>
                  {isExpanded ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
                </div>
              )}
            </div>
          );
        })}

      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatMessage;
