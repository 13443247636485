import axios from "axios";
import { message } from "antd";
import { Constant, Utils } from "@/common";

// 创建一个axios实例
const AxiosRequest = axios.create({
  baseURL: "" // 基础URL
  // timeout: 5000 // 请求超时时间
});

// 请求拦截器
AxiosRequest.interceptors.request.use(
  (config: any) => {
    // 在请求发送之前设置的基础值
    const header = {
      "Content-Type": "application/json",
      Accept: "*/*",
      uid: Utils.getLocalStorage("uid") || "",
      ...config.headers
    };
    config.headers = header;
    return config;
  },
  (error) => {
    console.log("request failed:", error.request);
    return Promise.reject(error);
  }
);

// 响应拦截器
AxiosRequest.interceptors.response.use(
  (response) => {
    const {
      config: { headers }
    } = response;

    // noStatusCode用以区分请求非内部接口，通过status判断请求状态
    if (headers.noStatusCode) {
      const { status, statusText } = response;

      if (status === Constant.HTTP_STATUS.SUCCESS) {
        return response;
      } else {
        message.error(statusText);
      }
    } else {
      // 内部接口在调用处通过code判断请求状态
      const { data } = response;

      return data;
    }

    console.log("--------------------------------------------");
    console.log(
      `%c REQUEST URL: %c${response.config.url}`,
      "color:rgb(21,104,247)",
      "color:rgb(179,180,182)"
    );
    console.log(response);
    console.log("--------------------------------------------");
  },
  (error) => {
    const { status } = error;
    if (status === Constant.HTTP_STATUS.NO_VISIT) {
      window.location.href = "/access-denied";
      return;
    }

    // 在接收到响应数据但是失败后的处理
    console.log("response failed.", error);
    return null;
    // return Promise.reject(error);
  }
);

// 封装GET请求，可添加config参数来覆盖默认配置
const SendGetRequest = (url: string, bodyParams = {}, config = {}) => {
  return AxiosRequest.get(url, { ...config, params: bodyParams });
};

// 封装POST请求，可添加config参数来覆盖默认配置
const SendPostRequest = (url: string, bodyParams = {}, config = {}) => {
  return AxiosRequest.post(url, bodyParams, config);
};

// 封装PUT请求，可添加config参数来覆盖默认配置
const SendPutRequest = (url: string, bodyParams = {}, config = {}) => {
  return AxiosRequest.put(url, bodyParams, config);
};

// 封装patch请求，可添加config参数来覆盖默认配置
const SendPatchRequest = (url: string, bodyParams = {}, config = {}) => {
  return AxiosRequest.patch(url, bodyParams, config);
};

// 封装delete请求，可添加config参数来覆盖默认配置
const SendDeleteRequest = (url: string, config = {}) => {
  return AxiosRequest.delete(url, config);
};

export {
  SendGetRequest,
  SendPostRequest,
  SendPutRequest,
  SendPatchRequest,
  SendDeleteRequest
};
