import React, { useState } from "react";
import { Collapse, Badge, Card } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  FolderFilled,
  LoadingOutlined,
  RightOutlined
} from "@ant-design/icons";
import classnames from "classnames";
import "./index.scss";

const TestResult = (props: any) => {
  const { data } = props;
  const [defaultActiveKey, setDefaultActiveKey] = useState(data[0].title);
  const success = 1;
  const error = -1;
  const loading = 0;

  return (
    <div className="test-result">
      <Collapse
        className="test-result-collapse"
        defaultActiveKey={defaultActiveKey}
      >
        {data.map((module: any) => {
          const passedTests = module.results.filter(
            (result: any) => result.test_result === success
          ).length;
          const totalTests = module.results.length;
          const { title, test_result, test_info } = module;
          const s = test_result === success;
          const e = test_result === error;
          const l = test_result === loading;

          return (
            <>
              {totalTests > 0 ? (
                <Collapse.Panel
                  key={title}
                  header={
                    <div className="test-result-collapse-header">
                      <span>
                        <FolderFilled />
                        {title}
                      </span>
                      <Badge
                        className="test-result-collapse-badge"
                        count={`${passedTests}/${totalTests}`}
                      />
                    </div>
                  }
                >
                  {module.results.map((result: any, index: number) => {
                    const { title, test_result, test_info } = result;
                    const suc = test_result === success;
                    const err = test_result === error;
                    const load = test_result === loading;

                    return (
                      <Card
                        key={index}
                        className={classnames("test-result-collapse-result", {
                          "test-result-collapse-result-passed": suc,
                          "test-result-collapse-result-error": err
                        })}
                      >
                        <div>
                          {load ? (
                            <div className="test-result-collapse-result-box">
                              <span>{title}</span>
                              <LoadingOutlined className="test-result-collapse-result-loading-icon" />
                            </div>
                          ) : (
                            <Collapse
                              className={classnames("", {
                                "test-result-collapse-result-error-details":
                                  err,
                                "test-result-collapse-result-passed-details":
                                  suc
                              })}
                              defaultActiveKey={[`${title}`]}
                              expandIcon={() => {
                                return test_info &&
                                  test_info.length === 0 ? null : (
                                  <RightOutlined />
                                );
                              }}
                            >
                              <Collapse.Panel
                                header={
                                  <div>
                                    <span>{title}</span>
                                    {suc && (
                                      <CheckOutlined className="test-result-collapse-result-passed-icon" />
                                    )}
                                    {err && (
                                      <CloseOutlined className="test-result-collapse-result-error-icon" />
                                    )}
                                  </div>
                                }
                                key={`${title}`}
                                collapsible={
                                  test_info && test_info.length === 0
                                    ? "disabled"
                                    : undefined
                                }
                              >
                                <ul>
                                  {test_info.map((d: any, idx: number) => {
                                    return (
                                      d.length > 0 && <li key={idx}>{d}</li>
                                    );
                                  })}
                                </ul>
                              </Collapse.Panel>
                            </Collapse>
                          )}
                        </div>
                      </Card>
                    );
                  })}
                </Collapse.Panel>
              ) : (
                <Card
                  className={classnames("test-result-collapse-result", {
                    "test-result-collapse-result-passed": s,
                    "test-result-collapse-result-error": e
                  })}
                >
                  <div>
                    {l ? (
                      <div className="test-result-collapse-result-box">
                        <span>{title}</span>
                        <LoadingOutlined className="test-result-collapse-result-loading-icon" />
                      </div>
                    ) : (
                      <Collapse
                        className={classnames("", {
                          "test-result-collapse-result-error-details": e,
                          "test-result-collapse-result-passed-details": s
                        })}
                        defaultActiveKey={[`${title}`]}
                        expandIcon={() => {
                          return test_info && test_info.length === 0 ? null : (
                            <RightOutlined />
                          );
                        }}
                      >
                        <Collapse.Panel
                          header={
                            <div>
                              <span>{title}</span>
                              {s && (
                                <CheckOutlined className="test-result-collapse-result-passed-icon" />
                              )}
                              {e && (
                                <CloseOutlined className="test-result-collapse-result-error-icon" />
                              )}
                            </div>
                          }
                          key={`${title}`}
                          collapsible={
                            test_info && test_info.length === 0
                              ? "disabled"
                              : undefined
                          }
                        >
                          <ul>
                            {test_info.map((d: any, idx: number) => {
                              return d.length > 0 && <li key={idx}>{d}</li>;
                            })}
                          </ul>
                        </Collapse.Panel>
                      </Collapse>
                    )}
                  </div>
                </Card>
              )}
            </>
          );
        })}
      </Collapse>
    </div>
  );
};

export default TestResult;
