import React, { useEffect, useState } from "react";
import { Utils } from "@/common";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const EnterApp: React.FC = () => {
  const paperContent = `Title: Mathematical Logic Solver
Abstract: A novel approach to scientific computation with integrated mathematical logic solvers...

Method:
1. Math Engine Integration
2. Symbol-Semantic Framework
3. Knowledge Processing System`;

  const generatedCode = `
import mathematical_engine as me
import symbol_processor as sp

# Define scientific computation function
def scientific_compute(data):
    engine = me.MathEngine()
    processor = sp.SymbolProcessor()
    
    # Process scientific computation
    result = engine.solve(
        framework=processor,
        data_structure=data
    )
    
    return result.optimize()`;
  const [paperText, setPaperText] = useState<string>("");
  const [codeText, setCodeText] = useState<string>("");
  const [showResult, setShowResult] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleEnterApp = () => {
    const uid = Utils.getLocalStorage("uid");
    if (uid && uid.length > 0) {
      navigate(`/home?uid=${uid}`);
    } else {
      navigate("/access-denied");
    }
  };

  const highlightCode = (code: string) => {
    return code.split("\n").map((line, i) => {
      if (/^\s*import /.test(line)) {
        return (
          <div key={i} className="code-import">
            {line}
          </div>
        );
      }
      if (/^\s*#/.test(line)) {
        return (
          <div key={i} className="code-comment">
            {line}
          </div>
        );
      }
      if (/^\s*def /.test(line)) {
        return (
          <div key={i} className="code-function">
            {line}
          </div>
        );
      }
      return (
        <div key={i} className="code-default">
          {line}
        </div>
      );
    });
  };

  useEffect(() => {
    let paperIndex = -1;
    let codeIndex = -1;

    const typePaper = setInterval(() => {
      if (paperIndex < paperContent.length) {
        setPaperText((prev) => prev + paperContent[paperIndex]);
        paperIndex++;
      } else {
        clearInterval(typePaper);
        setTimeout(() => {
          const typeCode = setInterval(() => {
            if (codeIndex < generatedCode.length) {
              setCodeText((prev) => prev + generatedCode[codeIndex]);
              codeIndex++;
            } else {
              clearInterval(typeCode);
              setTimeout(() => setShowResult(true), 300);
            }
          }, 10);
        }, 300);
      }
    }, 10);

    return () => clearInterval(typePaper);
  }, []);

  return (
    <div className="main-container">
      <div className="content-container">
        <div className="content-box paper-box">
          <div className="window-buttons">
            <div className="button l"></div>
            <div className="button c"></div>
            <div className="button r"></div>
          </div>
          <div className="content">
            <pre>
              {paperText}
              <span className="cursor">|</span>
            </pre>
          </div>
        </div>
        <div className="arrow-container">
          <span className="arrow">→</span>
        </div>
        <div className="content-box code-box">
          <div className="window-buttons">
            <div className="button l"></div>
            <div className="button c"></div>
            <div className="button r"></div>
          </div>
          <div className="content">
            <pre>{highlightCode(codeText)}</pre>
            <span className="cursor">|</span>
          </div>
        </div>
      </div>
      {showResult && (
        <div className="result-section">
          <h1 className="result-title">TRANSFORM DOCUMENTS INTO PRACTICE</h1>
          <button onClick={handleEnterApp} className="enter-button">
            Enter Application
          </button>
        </div>
      )}
    </div>
  );
};

export default EnterApp;
