import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import "github-markdown-css/github-markdown.css";
import "./index.scss";

const Markdown = (props: any) => {
  const { markdownData } = props;

  // 代码组件
  const CodeComponent: any = ({
    inline,
    className,
    children,
    ...props
  }: any) => {
    const matchLanguageClassnameRegexResult = /language-(\w+)/.exec(
      className || ""
    );

    if (!inline && children && matchLanguageClassnameRegexResult) {
      const cData = String(children).replace(/\n$/, "");
      const lan = matchLanguageClassnameRegexResult[1];

      return (
        <SyntaxHighlighter language={lan} PreTag="div" {...props}>
          {cData}
        </SyntaxHighlighter>
      );
    }

    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  };

  const components: any = {
    code: CodeComponent
  };

  return (
    <div className="markdown">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeKatex]}
        components={components}
      >
        {markdownData}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
